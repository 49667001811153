<template>
  <div style="background: white" id="aa">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_ht_disp_ctrl_add" />
      <!-- ハンディー画面制御登録画面 -->
      <v-container fluid>
        <v-row class="search-row">
          <v-form ref="defaultForm" class="d-flex">
            <!-- 営業所CD -->
            <div class="search-autocomplete first-search-item">
              <v-text-field
                dense
                readonly
                v-model="defaultForm.officeSelected"
                :label="$t('label.lbl_office_cd')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :rules="[rules.inputRequired]"
              ></v-text-field>
            </div>
            <span class="require asterisk-spacer">*</span>
            <!-- 取引先CD -->
            <div class="search-autocomplete first-search-item">
              <v-autocomplete
                dense
                v-model="defaultForm.clientSelected"
                :items="defaultForm.clientList"
                :label="$t('label.lbl_client_cd')"
                class="txt-single"
                persistent-hint
                margin-bottom="0"
                :hint="clientHint()"
                :rules="[rules.supplierRequired]"
                :readonly="defaultForm.isClientDisabledFlg"
                pa-3
              >
                <template slot="item" slot-scope="data">
                  <span class="auto-txt-size"> {{ data.item.text }} </span>
                </template>
              </v-autocomplete>
            </div>
            <span class="require asterisk-spacer">*</span>
          </v-form>
        </v-row>
        <v-row class="justify-space-between mb-0 mt-0">
          <!-- 戻るボタン -->
          <v-col cols="3" class="text-left">
            <div>
              <v-btn
                color="primary"
                id="btn-content-confirm"
                class="other-btn ml-0"
                @click="backBtnDialog()"
                >{{ $t("btn.btn_back") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <!-- タイプ選択 -->
        <v-row class="mt-0 pt-0" justify="center">
          <v-col :cols="2" class="justify-center pt-0">
            <v-form ref="selectTypeForm">
              <v-container>
                <v-row class="justify-center">
                  <v-col
                    class="text-center pa-2 mb-2"
                    style="border: 1px solid #bdbdbd; background-color: #effad9; font-weight: bold"
                  >
                    {{ $t("label.lbl_type_select") }}
                  </v-col>
                </v-row>
                <v-row class="justify-center mb-2">
                  <!-- 決定ボタン -->
                  <v-btn color="primary" id="btn-content-confirm" class="" @click="decisionBtn()">{{
                    $t("btn.btn_decision")
                  }}</v-btn>
                </v-row>
                <div
                  class="cards-container"
                  @mouseenter="showScrollbar"
                  @mouseleave="hideScrollbar"
                  style="height: calc(100vh - 279px)"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-card
                        dense
                        outlined
                        hover
                        class="my-1"
                        v-for="item in typeSelectForm.procList"
                        v-bind:key="item.bcrType"
                        :class="{ selected: item.bcrType === typeSelectForm.selectedType }"
                        @click="bcrTypeClick(item.bcrType)"
                      >
                        <v-card-title>{{ item.bcrType + "：" + item.codeName }}</v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-container>
            </v-form>
          </v-col>
          <!-- 項目設定 -->
          <v-col :cols="6" class="justify-center pt-0">
            <v-form ref="itemSettingsForm">
              <v-container>
                <v-row class="justify-center mb-2">
                  <v-col
                    class="text-center pa-2 mb-2"
                    style="
                      border: 1px solid #bdbdbd;
                      background-color: rgb(221, 235, 247);
                      font-weight: bold;
                    "
                  >
                    {{ $t("label.lbl_item_settings") }}
                  </v-col>
                </v-row>
                <v-row class="d-flex search-row">
                  <!-- 選択タイプ -->
                  <div class="pt-1">
                    <v-text-field
                      dense
                      readonly
                      v-model="itemSettings.selectedTypeName"
                      :label="$t('label.lbl_selected_type')"
                      class="txt-single"
                      persistent-hint
                      :rules="[rules.inputRequired]"
                    ></v-text-field>
                  </div>
                  <span class="require asterisk-spacer mr-auto">*</span>
                  <!-- 補足説明ボタン -->
                  <v-btn
                    color="primary"
                    id="btn-content-confirm"
                    class="mr-2"
                    @click="explanationBtn"
                    >{{ $t("btn.btn_supplementary_explanation") }}</v-btn
                  >
                  <!-- クリアボタン -->
                  <v-btn
                    color="primary"
                    id="btn-content-confirm"
                    class="mr-2"
                    @click="clearDialog"
                    >{{ $t("btn.btn_searchDataClear") }}</v-btn
                  >
                  <!-- 登録ボタン -->
                  <v-btn
                    color="primary"
                    id="btn-content-confirm"
                    class=""
                    @click="htAddBtnDialog"
                    >{{ $t("btn.btn_insert") }}</v-btn
                  >
                </v-row>
                <v-row>
                  <v-container fluid class="pa-0">
                    <v-data-table
                      id="listData"
                      fixed-header
                      :headers="itemSettings.itemSettingsListHeaders"
                      :items="itemSettings.itemSettingsList"
                      disable-filtering
                      disable-sort
                      disable-pagination
                      :hide-default-footer="true"
                      height="calc(100vh - 309px)"
                    >
                      <!-- 画面の動作 -->
                      <template v-slot:[`item.name`]="{ item }">
                        <div class="text-start">{{ item.name }}</div>
                      </template>
                      <!-- 有無 -->
                      <template v-slot:[`item.item`]="{ item }">
                        <v-autocomplete
                          dense
                          v-model="item.item"
                          :items="item.yesNoList"
                          item-text="remarks"
                          item-value="item03"
                          persistent-hint
                          :rules="item.yesNoList.length > 0 ? [rules.inputRequired] : []"
                        >
                          <template slot="item" slot-scope="data">
                            <span class="test">
                              {{ data.item.remarks }}
                            </span>
                          </template>
                        </v-autocomplete>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
          <!-- 登録済ラベル -->
          <v-col :cols="4" class="justify-center pt-0">
            <v-container>
              <v-row class="justify-center mb-2">
                <v-col
                  class="text-center pa-2 mb-2"
                  style="
                    border: 1px solid #bdbdbd;
                    background-color: rgb(217, 217, 217);
                    font-weight: bold;
                  "
                >
                  {{ $t("label.lbl_registered_label") }}
                </v-col>
              </v-row>
              <v-row class="d-flex search-row">
                <div class="pa-0 align-self-end mr-auto">
                  {{ $t("label.lbl_registration_status") }}
                </div>
                <!-- 検索ボタン -->
                <v-btn
                  color="primary"
                  id="btn-content-confirm"
                  class="mr-2"
                  @click="htSearchBtn(false)"
                  >{{ $t("btn.btn_search") }}</v-btn
                >
                <!-- 修正ボタン -->
                <v-btn color="primary" id="btn-content-confirm" class="mr-2" @click="editBtn">{{
                  $t("btn.btn_fix")
                }}</v-btn>
                <!-- 削除ボタン -->
                <v-btn color="primary" id="btn-content-confirm" @click="delBtnDialog">{{
                  $t("btn.btn_delete")
                }}</v-btn>
              </v-row>
              <v-row>
                <v-container fluid class="pa-0 pt-4">
                  <v-data-table
                    id="listData"
                    fixed-header
                    :headers="registeredLabelListHeaders"
                    :items="registeredLabelList"
                    disable-filtering
                    disable-sort
                    disable-pagination
                    :hide-default-footer="true"
                    height="calc(100vh - 309px)"
                  >
                    <template v-slot:[`item.check`]="{ item }">
                      <input type="checkbox" v-model="item.check" style="transform: scale(2)" />
                    </template>
                  </v-data-table>
                </v-container>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <SimpleDialog
        :isShow.sync="infoDialog.isOpen"
        :title="infoDialog.title"
        :message="infoDialog.message"
        :homePageFlag="infoDialog.homePageFlag"
        :firstPageFlag="infoDialog.firstPageFlag"
        :outsideClickNotCloseFlg="infoDialog.outsideClickNotCloseFlg"
      />
      <ConfirmDialog
        :isShow.sync="ConfirmDialog.isOpen"
        :message="ConfirmDialog.message"
        :okAction="ConfirmDialog.okAction"
        :redMessage="ConfirmDialog.redMessage"
        :screenFlag="ConfirmDialog.screenFlag"
        :changeFlag="ConfirmDialog.changeFlag"
      />
    </v-sheet>
  </div>
</template>
<script>
import Loading from "@/components/loading";
import sideMenu from "@/components/Menu";
import NavBar from "../../components/NavBar.vue";
import { appConfig } from "../../assets/scripts/js/AppConfig";
import { i18n } from "../../lang/lang.js";
import { getParameter } from "../../assets/scripts/js/GetParameter";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
import SimpleDialog from "@/components/SimpleDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import commonMixin from "@/mixins/CommonMixin";
import commonRules from "@/mixins/CommonRules";

export default {
  name: appConfig.SCREEN_ID.P_INI_007,
  components: {
    Loading,
    sideMenu,
    SimpleDialog,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
    entity: { type: Object, default: null },
  },
  mixins: [commonMixin, commonRules],
  data: () => ({
    openMenu: false,
    // WH工程
    // whProcList: ["100", "200", "300", "310", "400", "500", "800"],
    defaultForm: {
      // 営業所
      officeSelected: null,
      officeList: [],
      // 取引先
      clientSelected: null,
      clientList: [],
      isClientDisabledFlg: false,
    },
    typeSelectForm: {
      // 工程
      procList: [],
      selectedType: null,
    },
    itemSettings: {
      selectedType: null,
      selectedTypeName: "",
      selectedData: {},
      // バーコード項目
      bcrItemList: [],
      deleteList: [],
      selectItemList: [],
      // 設定項目
      itemSettingsList: [],
      itemSettingsListHeaders: [
        // 順番
        {
          text: i18n.tc("label.lbl_order"),
          value: "no",
          width: "2%",
          align: "center",
        },
        // 画面の動作
        {
          text: i18n.tc("label.lbl_screen_behavior"),
          value: "name",
          width: "8%",
          align: "center",
        },
        // 有無
        {
          text: i18n.tc("label.lbl_availability"),
          value: "item",
          width: "8%",
          align: "center",
        },
      ],
    },
    registeredLabelList: [],
    registeredLabelListHeaders: [
      // 選択
      {
        text: i18n.tc("label.lbl_select"),
        value: "check",
        width: "2%",
        align: "center",
      },
      // 工程
      {
        text: i18n.tc("label.lbl_opeHist"),
        value: "procCd",
        width: "4%",
        align: "center",
      },
    ],
  }),
  methods: {
    // メニューダイアログ
    showMenu() {
      this.openMenu = !this.openMenu;
    },
    showScrollbar(event) {
      event.target.style.overflowY = "auto";
    },
    hideScrollbar(event) {
      event.target.style.overflowY = "hidden";
    },
    // 初期化
    init() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      // 項目設定初期化
      this.itemSettingsInit();
      // 営業所
      const officeList = getParameter.getOffice();
      // 取引先
      const clientList = getParameter.getRelatedBiz(
        appConfig.ISGETCLIENT.CLIENT,
        dateTimeHelper.convertUTC()
      );
      // 工程
      const procList = getParameter.getBcrType({ physicalDb1: "m903" });
      // 有無項目
      const selectItemList = getParameter.getBcrItem({ physicalDb1: "m903" });
      Promise.all([officeList, clientList, procList, selectItemList])
        .then((result) => {
          // 画面の初期値を設定します。
          // 営業所
          this.defaultForm.officeList = result[0];
          this.defaultForm.officeSelected = this.defaultForm.officeList[0].text;
          // 取引先
          this.defaultForm.clientList = result[1];
          // 工程
          this.typeSelectForm.procList = result[2];
          // 項目
          this.itemSettings.selectItemList = result[3];
          // 取引先が1件の場合、取引先を自動選択する
          this.defaultForm.clientList.length == 1
            ? (this.defaultForm.clientSelected = this.defaultForm.clientList[0].value)
            : "";
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    // 取引先
    clientHint() {
      const client = this.defaultForm.clientList.find(
        (data) => data.value == this.defaultForm.clientSelected
      );
      return client ? client.name : "";
    },
    // タイプ選択
    bcrTypeClick(bcrType) {
      this.typeSelectForm.selectedType = bcrType;
    },
    // 項目設定初期化
    itemSettingsInit() {
      this.itemSettings.deleteList = [];
      // バリデーションチェックをリセット
      this.$refs.itemSettingsForm.resetValidation();
      for (let i = 1; i <= 20; i++) {
        this.$set(this.itemSettings.itemSettingsList, i - 1, {
          no: i,
          name: "",
          item: "0",
          deleteFlg: "0",
          yesNoList: [],
        });
      }
      // タイプ選択情報
      this.itemSettings.selectedType = null;
      this.itemSettings.selectedTypeName = "";
      // 取引先
      this.defaultForm.isClientDisabledFlg = false;
      // 選択データ
      this.itemSettings.selectedData = {};
    },
    /**
     * 戻るダイアログ
     */
    backBtnDialog() {
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_004_W");
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.backBtn;
    },
    /**
     * 戻る
     */
    backBtn() {
      this.$router.push({
        name: appConfig.SCREEN_ID.P_INI_004,
      });
    },
    /**
     * 決定ボタン
     */
    decisionBtn() {
      if (this.$refs.defaultForm.validate()) {
        const item = this.typeSelectForm.procList.find(
          (item) => item.bcrType === this.typeSelectForm.selectedType
        );
        if (item) {
          // 項目設定初期化
          this.itemSettingsInit();
          // タイプ選択情報
          this.itemSettings.selectedType = item;
          this.itemSettings.selectedTypeName = item.bcrType + "：" + item.codeName;
          // 取引先
          const client = this.defaultForm.clientList.find(
            (data) => data.value == this.defaultForm.clientSelected
          );
          // 営業所別モジュール情報取得
          getParameter
            .getOfficeModuleInfo({
              officeCd: sessionStorage.getItem("office_cd"),
              clientCd: client.clientCd,
            })
            .then((result) => {
              const moduleCdArray = result.map((obj) => obj.moduleCd);
              // 項目設定
              const targetList = this.itemSettings.selectItemList.filter(
                (data) =>
                  data.bcrItem.toLowerCase().startsWith("cm") ||
                  data.bcrItem == item.bcrType ||
                  moduleCdArray.some((moduleCd) => {
                    return `${item.bcrType}${moduleCd}` === data.bcrItem;
                  })
                /* || (!this.whProcList.includes(item.bcrType) && data.bcrItem.toLowerCase().startsWith("dp")) */
              );
              const targetGroupList = [...new Set(targetList.map((data) => data.item01))];
              let i = 0;
              targetGroupList.forEach((data) => {
                const filterList = targetList.filter((type) => type.item01 == data);
                const filterGroupList = [...new Set(filterList.map((item) => item.item02))];
                filterGroupList.sort((a, b) => {
                  return parseInt(a.item02) < parseInt(b.item02);
                });
                const groupedList = filterGroupList.reduce((groups, groupValue) => {
                  groups.push(filterList.filter((group) => group.item02 === groupValue));
                  return groups;
                }, []);
                groupedList.forEach((grouped) => {
                  console.debug(grouped);
                  this.itemSettings.itemSettingsList[i].yesNoList = grouped.sort(
                    (item1, item2) => item1.item03 - item2.item03
                  );
                  this.itemSettings.itemSettingsList[i].name = grouped[0].codeName;
                  this.itemSettings.itemSettingsList[i].item01 = grouped[0].item01;
                  this.itemSettings.itemSettingsList[i].item02 = grouped[0].item02;
                  this.itemSettings.itemSettingsList[i].item = grouped[0].item03;
                  i++;
                });
              });
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            });
        } else {
          this.infoDialog.message = i18n.tc("check.chk_select");
          this.infoDialog.title = i18n.tc("btn.btn_ok");
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        }
      }
    },
    /**
     * 補足説明
     */
    explanationBtn() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const explanationUrl = getParameter.getDownloadMstTempleteFile({
        path: "Master_Supplementary_Document",
        fileName: "Master_Supplementary_Document.xlsx",
      });
      Promise.all([explanationUrl])
        .then((result) => {
          location.href = result[0];
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          // ローディング画面表示OFF
          this.loadingCounter = 0;
        });
    },
    /**
     * クリアダイアログ
     */
    clearDialog() {
      this.ConfirmDialog.title = appConfig.DIALOG.title;
      this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
        i18n.tc("btn.btn_searchDataClear"),
      ]);
      this.ConfirmDialog.isOpen = true;
      this.ConfirmDialog.screenFlag = true;
      this.ConfirmDialog.changeFlag = false;
      this.ConfirmDialog.okAction = this.clear;
    },
    /**
     * クリア
     */
    clear() {
      // 項目設定初期化
      this.itemSettingsInit();
    },
    /**
     * 登録ダイアログ
     */
    htAddBtnDialog() {
      if (this.$refs.defaultForm.validate() && this.$refs.itemSettingsForm.validate()) {
        const dataCnt = this.itemSettings.itemSettingsList.filter((data) => !!data.item01).length;
        if (dataCnt > 0) {
          this.ConfirmDialog.title = appConfig.DIALOG.title;
          this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
            i18n.tc("label.lbl_regist"),
          ]);
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
          this.ConfirmDialog.okAction = this.htAddBtn;
        } else {
          this.infoDialog.message = messsageUtil.getMessage("P-DST-002_001_E", [
            i18n.tc("label.lbl_item_settings"),
          ]);
          this.infoDialog.title = i18n.tc("btn.btn_ok");
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        }
      }
    },
    /**
     * 登録
     */
    htAddBtn() {
      this.loadingCounter = 1;
      const upsertData = {};
      const groupList = [
        ...new Set(
          this.itemSettings.itemSettingsList.map((data) => data.item01).filter((data) => !!data)
        ),
      ];
      var space1 = "";
      var space100 = "";
      space1 = space1.padStart(1);
      space100 = space100.padStart(100);
      console.log(this.itemSettings.selectedData);
      if (Object.keys(this.itemSettings.selectedData).length === 0) {
        // 初期データ
        this.itemSettings.selectedData["officeSid"] = sessionStorage.getItem("sales_office_sid");
        this.itemSettings.selectedData["clientSid"] = this.defaultForm.clientSelected;
        this.itemSettings.selectedData["procCd"] = this.itemSettings.selectedType.bcrType;
        this.itemSettings.selectedData["yobi01"] = space1;
        this.itemSettings.selectedData["yobi02"] = space1;
        this.itemSettings.selectedData["yobi03"] = space1;
        this.itemSettings.selectedData["yobi04"] = space1;
        this.itemSettings.selectedData["yobi05"] = space1;
        this.itemSettings.selectedData["yobi10"] = space100;
        this.itemSettings.selectedData["yobi11"] = space100;
        this.itemSettings.selectedData["yobi12"] = space100;
        this.itemSettings.selectedData["yobi13"] = space100;
        this.itemSettings.selectedData["yobi14"] = space100;
        this.itemSettings.selectedData["yobi15"] = space100;
        this.itemSettings.selectedData["deleteFlg"] = "0";
      }

      groupList.forEach((data) => {
        const filterList = this.itemSettings.itemSettingsList.filter((item) => item.item01 == data);
        const yobiKey = data.replace(/_/g, "");
        const strLength = this.itemSettings.selectedData[yobiKey].length;
        var yobi = this.itemSettings.selectedData[yobiKey];
        for (let i = 0; i < filterList.length; i++) {
          const position = filterList[i].item02;
          const updateStr = filterList[i].item;
          var forwardStr = "";
          var backStr = "";
          if (strLength > 0) {
            if (strLength == 1) {
              yobi = updateStr;
            } else {
              forwardStr = yobi.substring(0, position - 1);
              backStr = yobi.substring(position);
              yobi = forwardStr + updateStr + backStr;
            }
          }
        }
        upsertData[yobiKey] = yobi;
      });

      Object.assign(this.itemSettings.selectedData, upsertData);
      const itemList = [];
      itemList.push(this.itemSettings.selectedData);
      const htAddResult = this.apiHtDispCtrlUpsert({ ItemList: itemList }, false);
      Promise.all([htAddResult])
        .then(() => {
          this.htSearchBtn(true);
          // 項目設定初期化
          this.itemSettingsInit();
          // タイプ選択を外す
          this.typeSelectForm.selectedType = null;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    /**
     * 検索
     */
    htSearchBtn(refreshFlg) {
      // 営業所、取引先のバリデーション
      if (refreshFlg || this.$refs.defaultForm.validate()) {
        let conditions = {};
        let continueFlg = true;
        if (refreshFlg) {
          if (Object.keys(this.searchFilter).length != 0) {
            conditions = this.searchFilter;
          } else {
            // 検索痕跡が存在しない場合、処理しない
            continueFlg = false;
          }
        } else {
          conditions = { clientSid: this.defaultForm.clientSelected };
        }
        if (continueFlg) {
          this.loadingCounter = 1;
          const qrTmpList = this.apiHtDispCtrlSearch(conditions);
          Promise.all([qrTmpList])
            .then((result) => {
              const list = [];
              result[0].forEach((row) => {
                list.push({
                  check: false,
                  officeSid: row.officeSid,
                  clientSid: row.clientSid,
                  procCd: row.procCd,
                  yobi01: row.yobi01,
                  yobi02: row.yobi02,
                  yobi03: row.yobi03,
                  yobi04: row.yobi04,
                  yobi05: row.yobi05,
                  yobi10: row.yobi10,
                  yobi11: row.yobi11,
                  yobi12: row.yobi12,
                  yobi13: row.yobi13,
                  yobi14: row.yobi14,
                  yobi15: row.yobi15,
                  updateDatetime: row.updateDatetime,
                  deleteFlg: row.deleteFlg,
                });
              });
              this.registeredLabelList = list;
            })
            .catch((ex) => {
              this.infoDialog.message = ex;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
            })
            .finally(() => {
              this.loadingCounter = 0;
            });
        }
      }
    },
    /**
     * 修正
     */
    editBtn() {
      const checkCnt = this.registeredLabelList.filter((data) => data.check === true).length;
      if (checkCnt === 1) {
        // 項目設定初期化
        this.itemSettingsInit();
        // 取引先
        this.defaultForm.isClientDisabledFlg = true;
        // 選択データ
        const checkData = this.registeredLabelList.find((data) => data.check === true);
        if (checkData) {
          // 選択タイプ
          const selectType = this.typeSelectForm.procList.find(
            (item) => item.bcrType == checkData.procCd
          );
          this.itemSettings.selectedType = selectType;
          this.itemSettings.selectedTypeName = selectType
            ? selectType.bcrType + "：" + selectType.codeName
            : "";
          // 項目設定
          Object.assign(this.itemSettings.selectedData, checkData);
          delete this.itemSettings.selectedData.check;
          // 取引先
          const client = this.defaultForm.clientList.find(
            (data) => data.value == checkData.clientSid
          );
          // 営業所別モジュール情報取得
          getParameter
            .getOfficeModuleInfo({
              officeCd: sessionStorage.getItem("office_cd"),
              clientCd: client.clientCd,
            })
            .then((result) => {
              const moduleCdArray = result.map((obj) => obj.moduleCd);
              const targetList = this.itemSettings.selectItemList.filter(
                (data) =>
                  data.bcrItem.toLowerCase().startsWith("cm") ||
                  data.bcrItem == checkData.procCd ||
                  moduleCdArray.some((moduleCd) => {
                    return `${checkData.procCd}${moduleCd}` === data.bcrItem;
                  })
                /* ||
              (!this.whProcList.includes(checkData.procCd.toString()) &&
                data.bcrItem.toLowerCase().startsWith("dp")) */
              );
              const targetGroupList = [...new Set(targetList.map((data) => data.item01))];
              let i = 0;
              targetGroupList.forEach((data) => {
                const filterList = targetList.filter((type) => type.item01 == data);
                const filterGroupList = [...new Set(filterList.map((item) => item.item02))];
                const groupedList = filterGroupList.reduce((groups, groupValue) => {
                  groups.push(filterList.filter((group) => group.item02 === groupValue));
                  return groups;
                }, []);
                groupedList.forEach((grouped) => {
                  this.itemSettings.itemSettingsList[i].yesNoList = grouped.sort(
                    (item1, item2) => item1.item03 - item2.item03
                  );
                  this.itemSettings.itemSettingsList[i].name = grouped[0].codeName;
                  this.itemSettings.itemSettingsList[i].item01 = grouped[0].item01;
                  this.itemSettings.itemSettingsList[i].item02 = grouped[0].item02;
                  const yobiKey = grouped[0].item01.replace(/_/g, "");
                  const multipleFlg = parseInt(yobiKey.match(/\d+$/)[0]) >= 10;
                  let yobiValue = multipleFlg
                    ? checkData[yobiKey]?.[grouped[0].item02 - 1] ?? grouped[0].item03
                    : checkData[yobiKey];
                  this.itemSettings.itemSettingsList[i].item = yobiValue.trim()
                    ? yobiValue
                    : grouped[0].item03;
                  i++;
                });
              });
            });
        }
        // タイプ選択を外す
        this.typeSelectForm.selectedType = null;
      } else if (checkCnt > 1) {
        this.infoDialog.message = messsageUtil.getMessage("P-TMP-903_003_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      } else {
        this.infoDialog.message = messsageUtil.getMessage("P-EXT-003_001_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },
    /**
     * 削除ダイアログ
     */
    delBtnDialog() {
      const checkCnt = this.registeredLabelList.filter((item) => item.check === true).length;
      if (checkCnt > 0) {
        this.ConfirmDialog.title = appConfig.DIALOG.title;
        this.ConfirmDialog.message = messsageUtil.getMessage("P-COM-001_006_C", [
          i18n.tc("label.lbl_delete"),
        ]);
        this.ConfirmDialog.isOpen = true;
        this.ConfirmDialog.screenFlag = true;
        this.ConfirmDialog.changeFlag = false;
        this.ConfirmDialog.okAction = this.delBtn;
      } else {
        this.infoDialog.message = messsageUtil.getMessage("P-EXT-003_001_E");
        this.infoDialog.title = i18n.tc("btn.btn_ok");
        this.infoDialog.isOpen = true;
        this.infoDialog.firstPageFlag = true;
      }
    },
    /**
     * 削除
     */
    delBtn() {
      const delList = this.registeredLabelList.filter((data) => data.check === true);
      delList.forEach((data) => {
        // 削除フラグ
        data.deleteFlg = "1";
        delete data.check;
      });
      this.loadingCounter = 1;
      const tmpDelResult = this.apiHtDispCtrlUpsert({ ItemList: delList }, true);
      Promise.all([tmpDelResult])
        .then(() => {
          this.htSearchBtn(true);
          // 項目設定初期化
          this.itemSettingsInit();
          // タイプ選択を外す
          this.typeSelectForm.selectedType = null;
        })
        .catch((ex) => {
          this.infoDialog.message = ex;
          this.infoDialog.title = appConfig.DIALOG.title;
          this.infoDialog.isOpen = true;
          this.infoDialog.firstPageFlag = true;
        })
        .finally(() => {
          this.loadingCounter = 0;
        });
    },
    /**
     * API：HT画面制御の取得
     */
    apiHtDispCtrlSearch(conditions) {
      const config = this.$httpClient.createGetApiRequestConfig();
      Object.assign(config.params, conditions);
      return new Promise((resolve, reject) => {
        this.$httpClient
          .secureGet(appConfig.API_URL.INI_HT_DISP_CTRL_LIST, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              // 検索条件
              this.searchFilter = conditions;
              resolve(jsonData.resIdv.ItemList);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(ex);
          });
      });
    },
    /**
     * API：HT画面制御の登録・修正・削除
     */
    apiHtDispCtrlUpsert(conditions, deleteFlg, messageIgnoreFlg = false) {
      const body = this.$httpClient.createRequestBodyConfig();
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_INI_007; // 画面ID
      Object.assign(body.reqIdv, conditions);
      return new Promise((resolve, reject) => {
        this.$httpClient
          .securePost(appConfig.API_URL.INI_HT_DISP_CTRL_LIST, body, appConfig.APP_CONFIG)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));
            // 正常時
            if (jsonData.resCom.resComCode == appConfig.RESCOMCODE_SUCCESS) {
              if (!messageIgnoreFlg) {
                if (!deleteFlg) {
                  // 登録
                  this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
                } else {
                  // 削除
                  this.infoDialog.message = messsageUtil.getMessage("P-MST-014_001_C");
                }
                this.infoDialog.title = appConfig.DIALOG.title;
                this.infoDialog.isOpen = true;
                this.infoDialog.firstPageFlag = true;
              }
              resolve(response);
            } else {
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.infoDialog.isOpen = true;
              this.infoDialog.firstPageFlag = true;
              reject(jsonData.resCom.resComMessage);
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.infoDialog.isOpen = true;
            this.infoDialog.firstPageFlag = true;
            reject(ex);
          });
      });
    },
  },
  computed: {},
  watch: {
    isMenu: function (newValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
  created() {},
};
</script>
<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
.cards-container {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.selected {
  border-top: groove 3px #00bfff; /* 上 */
  border-bottom: groove 3px #00bfff; /* 下 */
  border-left: groove 3px #00bfff; /* 左 */
  border-right: groove 3px #00bfff; /* 右 */
}
</style>
